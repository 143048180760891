<template>
  <div class="h-100 m-0">

    <div class="row m-0 p-0 pt-4 pb-4">
      <!-- Add Service Advertise Section - Form section -->
      <div class="col-12 col-lg-4 col-md-12 col-sm-12 col-xs-12">

        <div class="row h-100">
          <div class="col-12 col-md-12">
            <div class="card shadow sm">
              <div class="card-body">
                <h3 v-if="this.editing" class="text-center">Edit Service Advertise</h3>
                <h3 v-else class="text-center">Add Service Advertise</h3>
                <hr/>
                <form action="javascript:void(0)" class="row" method="post" enctype="multipart/form-data" id="addEditServiceForm">

                  <div class="form-group col-12 pt-2">
                    <label class="pb-2">What type of occupation you represent? <span class="required">*</span></label>
                    <v-select
                        v-model="serviceTypeOccupation"
                        name="serviceTypeOccupation"
                        :options="serviceTypeOccupationsOptions"
                        item-text="type"
                        item-value="id"
                        label="type"
                        placeholder="Start typing occupation name..."
                        class="select-placeholder"
                    ></v-select>
                  </div>

<!--                  <div v-if="serviceType" class="form-group col-12">-->
<!--                    &lt;!&ndash; Start Horizontal Line with Icon &ndash;&gt;-->
<!--                    <div class="horizontal-separator-box">-->
<!--                      <span class="hr-line-short"></span>-->
<!--                      <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>-->
<!--                      <span class="hr-line-short"></span>-->
<!--                    </div>-->
<!--                    &lt;!&ndash; End Horizontal Line with Icon &ndash;&gt;-->

<!--                    &lt;!&ndash; Service Type Information &ndash;&gt;-->
<!--                    <small class="p-3">{{ this.serviceTypeOptions[serviceType -1].description }}</small>-->

<!--                    &lt;!&ndash; Start Horizontal Line with Icon &ndash;&gt;-->
<!--                    <div class="horizontal-separator-box">-->
<!--                      <span class="hr-line-short"></span>-->
<!--                      <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>-->
<!--                      <span class="hr-line-short"></span>-->
<!--                    </div>-->
<!--                    &lt;!&ndash; End Horizontal Line with Icon &ndash;&gt;-->
<!--                  </div>-->

                  <div v-if="serviceTypeOccupation" class="form-group col-12 mt-2">
                    <label for="serviceTitle" class="pb-2">Advertise Title</label><span class="required"> *</span>
                    <input type="text" name="serviceTitle" v-model="form.service_title" maxlength="255" id="serviceTitle" placeholder="Enter advertise title" class="form-control">
                    <p v-if="this.validationErrors && this.validationErrors.service_title" class="required-info text-center">
                      {{ this.validationErrors.service_title[0] }}
                    </p>
                  </div>

                  <!-- Start Service Details and Toggles Fields -->
                  <div v-if="serviceTypeOccupation" class="col-12 text-start mt-2 mb-2 p-0">

                    <!-- Start Service Address Fields -->
                    <div v-show="serviceTypeOccupation" class="row m-0 p-0 text-center">
                      <!-- Service Area Cover Address -->
                      <div class="col-12 p-0 mt-1 mb-1">
                        <div class="row m-0 p-0">
                          <!-- Should be col-8, for time being col-12 as we do not do manual address enter -->
                          <div class="col-12">
                            <label class="pb-2">Area Covered <span class="required">*</span></label>
                            <div id="geocoderAddEditServiceAreaCover"></div>
<!--                            <pre id="result"></pre>-->
                          </div>
<!--                          <div class="col-4">-->
<!--                            <label class="pb-2">Enter manually</label>-->
<!--                            <label class="switch mt-2">-->
<!--                              <input type="checkbox" v-model="is_manual_address">-->
<!--                              <span class="slider round"></span>-->
<!--                            </label>-->
<!--                          </div>-->
                        </div>
                        <p v-if="this.validationErrors && this.validationErrors.area_cover_full_address" class="required-info text-center">
                          {{ this.validationErrors.area_cover_full_address[0] }}
                        </p>
                      </div>
                    </div>

<!--                    <div v-show="serviceType && is_manual_address" class="row m-0 p-0">-->
<!--                      &lt;!&ndash; Manual Address &ndash;&gt;-->
<!--                      <div class="col-12 p-0 mt-1 mb-1">-->
<!--                        <div class="row m-0 p-0">-->
<!--                          <div class="col-8 pt-2">-->
<!--                            line_1, line_2, postcode, city, county, country-->
<!--                          </div>-->
<!--                          <div class="col-4 pt-2">-->

<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
                    <!-- End Service Area Cover Address Fields -->

                    <!-- Start Horizontal Line with Icon -->
                    <div v-if="serviceTypeOccupation" class="horizontal-separator-box">
                      <span class="hr-line-short"></span>
                      <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                      <span class="hr-line-short"></span>
                    </div>
                    <!-- End Horizontal Line with Icon -->

                    <!-- Start Upload Service Images -->
                    <div v-show="serviceTypeOccupation" class="row m-0 p-0 text-center">
                      <!-- Service Images Upload -->
                      <div class="col-12 p-0 mt-1 mb-1">
                        <div class="row m-0 p-0">
                          <div class="col-12">
<!--                            <p class="pb-2">Service Images | Left: <span style="color: red;">{{ calculateAllowedImages(form.previewServiceImages, form.editServiceImages, 7) }}</span></p>-->
                            <p class="pb-2">Service Images | Uploaded: <span style="color: red;">{{ calculateImages(form.previewServiceImages, form.editServiceImages) }} / 7</span></p>
                            <div class="dropbox">
                              <input type="file" accept="image/*" @change="uploadServiceImages" multiple="multiple" name="previewServiceImages" id="serviceImages" class="input-file">
                              <p>Click on the box or drop the images into the box in order to upload them (Max file size: 2MB)</p>
                              <div class="row p-0 m-0 mb-2">
                                <div v-for="image in form.previewServiceImages" class="col-4 p-1 mt-1 mb-1">
                                  <img v-if="image" :src="image" class="uploading-property-images" />
                                </div>
                              </div>
                            </div>
                            <div v-if="form.editServiceImages && form.editServiceImages.length > 0" class="col-12">
                              <ul class="list-unstyled text-start p-1">
                                <li v-for="(editServiceImage, index) in form.editServiceImages">
                                  <div class="row g-0 m-0 p-1">
                                    <div class="col-1">
                                      <div class="position-relative">
                                        <button @click="deleteImage(editServiceImage.id, editServiceImage.document_type_id, editServiceImage.file_name)" class="delete-btn">X</button>
                                      </div>
                                    </div>
                                    <div class="col-5">
                                      {{ editServiceImage.file_name }}
                                    </div>
                                    <div class="col-6">
                                      <img :src="`${$s3bucket}${editServiceImage.file_path}`" @error="setDefaultImg"
                                           class="d-block img-fluid uploading-property-images"
                                           alt="..."
                                      >
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <!--
                          It would also be better to display name of the image rather than some random number..
                          not sure how to achieve that though
                        -->
                        <div v-for="(error, key, index) in this.validationErrors">
                          <p v-if="key.includes('serviceImages')" class="required-info text-center">
                            Image number {{ Number(key.substr(key.indexOf('.') + 1)) + 1 }} - {{ error[0] }}
                          </p>
                          <p v-if="key.includes('service_images_limit')" class="required-info text-center">
                            {{ error[0] }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- End Upload Service Images -->

                    <div class="form-group col-12 p-0 text-center">
                      <!-- Start Horizontal Line with Icon -->
                      <div class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->

                      <!-- Information -->
                      <small class="p-3 fw-bold">Working Hours</small>

                      <!-- Start Horizontal Line with Icon -->
                      <div class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->
                    </div>

                    <!-- Availability -->
                    <div class="row m-0 p-0">
                      <!-- Working Hours From Flexible -->
                      <div class="col-6 p-0 mt-2 mb-2">
                        <div class="row m-0 p-0">
                          <div class="col-9">
                            <div class="list-relative">
                                <span data-tooltip="tooltip">
                                  <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                    <p class="options-paragraph m-0 p-0 me-2 d-inline">Flexible Working Hours From</p>
                                    <font-awesome-icon icon="calendar-check" class="icon-fa" />
                                    <span class="tooltiptext">Do you offer flexible working hours?</span>
                                  </div>
                                </span>
                            </div>
                          </div>
                          <div class="col-3">
                            <label class="switch">
                              <input type="checkbox" v-model="form.working_hours_from_flexible">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- Working Hours To Flexible -->
                      <div class="col-6 p-0 mt-2 mb-2">
                        <div class="row m-0 p-0">
                          <div class="col-9">
                            <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Flexible Working Hours To</p>
                                        <font-awesome-icon icon="calendar-minus" class="icon-fa" />
                                        <span class="tooltiptext">Do you offer flexible working hours?</span>
                                      </div>
                                    </span>
                            </div>
                          </div>
                          <div class="col-3">
                            <label class="switch">
                              <input type="checkbox" v-model="form.working_hours_to_flexible">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row m-0 p-0">
                      <div class="col-6 m-0 p-0 mt-1 mb-1 text-center" id="workingHoursFromTimers">
                        <!-- I will use separate time date-pickers for working_hours_from and working_hours_to and singular_date -->
                        <div :class="form.working_hours_from_flexible ? 'form-control mt-1 mb-1' : 'mt-1 mb-1'">
                          <small><strong>{{ !form.working_hours_from_flexible ? 'From:' : 'From: Flexible'}}</strong></small>
                        </div>
                        <date-picker
                            v-if="!form.working_hours_from_flexible"
                            mode="time"
                            v-model="form.working_hours_from"
                            is24hr
                            :minute-increment="15"
                            :reactive=true
                        />
                      </div>
                      <div class="col-6 m-0 p-0 mt-1 mb-1 text-center" id="workingHoursToTimers">
                        <!-- I will use separate time date-pickers for check_in_from and check_in_to and singular_date -->
                        <div :class="form.working_hours_to_flexible ? 'form-control mt-1 mb-1' : 'mt-1 mb-1'">
                          <small><strong>{{ !form.working_hours_to_flexible ? 'To:' : 'To: Flexible'}}</strong></small>
                        </div>
                        <date-picker
                            v-if="!form.working_hours_to_flexible"
                            mode="time"
                            v-model="form.working_hours_to"
                            is24hr
                            :minute-increment="15"
                            :reactive=true
                        />
                      </div>
                    </div>

                    <!-- Start Horizontal Line with Icon -->
                    <div v-if="serviceTypeOccupation" class="horizontal-separator-box">
                      <span class="hr-line-short"></span>
                      <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                      <span class="hr-line-short"></span>
                    </div>
                    <!-- End Horizontal Line with Icon -->

                    <!-- Start Main input fields -->
                    <div v-if="serviceTypeOccupation" class="row m-0 p-0">
                      <!-- Price -->
                      <div class="col-12 p-0 mt-1 mb-1">
                        <div class="row m-0 p-0">
                          <div class="col-3 pt-2">
                            <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Price</p>
                                  <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                  <span class="required"> *</span>
                                  <span class="tooltiptext">What is the price for your service?</span>
                                </div>
                              </span>
                            </div>
                          </div>
                          <div class="col-3">
                            <input
                                type="number"
                                placeholder="£"
                                min="0.00"
                                max="999999999999.99"
                                v-model="form.price"
                                class="options-input options-price-input select-placeholder"
                                @focus="handleFocus($event)"
                                @focusout="handleFocusOut(form.price, $event)"
                            >
                          </div>
                          <div class="col-6">
                            <select
                                v-if="serviceTypeOccupation"
                                v-model="form.service_payment_type_id"
                                class="options-select form-select select-placeholder cursor-pointer"
                                @focus="handleFocus($event)"
                                @focusout="handleFocusOut(form.service_payment_type_id, $event)"
                                tabindex="-1"
                                aria-label="servicePaymentType"
                                name="servicePaymentType"
                                id="servicePaymentType"
                            >
                              <option value="" disabled>Select payment frequency</option>
                              <option :value="2">Per Hour</option>
                              <option :value="3">Per Night</option>
                              <option :value="4">Per Week</option>
                              <option :value="5">Per Month</option>
                              <option :value="6">Per Job</option>
                              <option :value="7">Per Square Meter</option>
                              <option :value="8">To Be Discussed (TBD)</option>
                            </select>
                          </div>
                        </div>
                        <p v-if="this.validationErrors && this.validationErrors.price" class="required-info text-center">
                          {{ this.validationErrors.price[0] }}
                        </p>
                        <p v-if="this.validationErrors && this.validationErrors.service_payment_type_id" class="required-info text-center">
                          {{ this.validationErrors.service_payment_type_id[0] }}
                        </p>
                      </div>

                      <!-- Minimum Value -->
                      <div v-if="serviceTypeOccupation && serviceTypeOccupation.type === 'Lender'" class="col-12 p-0 mt-1 mb-1">
                        <div class="row m-0 p-0">
                          <div class="col-6 pt-2">
                            <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Minimum</p>
                                  <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                  <span class="required"> *</span>
                                  <span class="tooltiptext">What is the minimum you can lend?</span>
                                </div>
                              </span>
                            </div>
                          </div>
                          <div class="col-6">
                            <input
                                type="number"
                                placeholder="£"
                                min="0"
                                max="999999999999"
                                v-model="form.minimum"
                                class="options-input options-price-input select-placeholder"
                                @focus="handleFocus($event)"
                                @focusout="handleFocusOut(form.minimum, $event)"
                            >
                          </div>
                        </div>
                        <p v-if="this.validationErrors && this.validationErrors.minimum" class="required-info text-center">
                          {{ this.validationErrors.minimum[0] }}
                        </p>
                      </div>

                      <!-- Maximum Value -->
                      <div v-if="serviceTypeOccupation && serviceTypeOccupation.type === 'Lender'" class="col-12 p-0 mt-1 mb-1">
                        <div class="row m-0 p-0">
                          <div class="col-6 pt-2">
                            <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Maximum</p>
                                  <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                  <span class="required"> *</span>
                                  <span class="tooltiptext">What is the maximum you can lend?</span>
                                </div>
                              </span>
                            </div>
                          </div>
                          <div class="col-6">
                            <input
                                type="number"
                                placeholder="£"
                                min="0"
                                max="999999999999"
                                v-model="form.maximum"
                                class="options-input options-price-input select-placeholder"
                                @focus="handleFocus($event)"
                                @focusout="handleFocusOut(form.maximum, $event)"
                            >
                          </div>
                        </div>
                        <p v-if="this.validationErrors && this.validationErrors.maximum" class="required-info text-center">
                          {{ this.validationErrors.maximum[0] }}
                        </p>
                      </div>

                      <!-- APR -->
                      <div v-if="serviceTypeOccupation && serviceTypeOccupation.type === 'Lender'" class="col-12 p-0 mt-1 mb-1">
                        <div class="row m-0 p-0">
                          <div class="col-6 pt-2">
                            <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">APR</p>
                                  <font-awesome-icon icon="percentage" class="icon-fa" />
                                  <span class="tooltiptext">
                                    APR (Annual Percentage Rate) is used for personal loans, credit cards and hire purchase agreements.
                                  </span>
                                </div>
                              </span>
                            </div>
                          </div>
                          <div class="col-6">
                            <input
                                type="number"
                                placeholder="%"
                                min="0.00"
                                max="999.99"
                                v-model="form.apr"
                                class="options-input options-price-input select-placeholder"
                                @focus="handleFocus($event)"
                                @focusout="handleFocusOut(form.apr, $event)"
                            >
                          </div>
                        </div>
                        <p v-if="this.validationErrors && this.validationErrors.apr" class="required-info text-center">
                          {{ this.validationErrors.apr[0] }}
                        </p>
                      </div>

                      <!-- APRC -->
                      <div v-if="serviceTypeOccupation && serviceTypeOccupation.type === 'Lender'" class="col-12 p-0 mt-1 mb-1">
                        <div class="row m-0 p-0">
                          <div class="col-6 pt-2">
                            <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">APRC</p>
                                  <font-awesome-icon icon="percentage" class="icon-fa" />
                                  <span class="tooltiptext">
                                    APRC (annual percentage rate of charge) is used for mortgages and secured homeowner loans.
                                  </span>
                                </div>
                              </span>
                            </div>
                          </div>
                          <div class="col-6">
                            <input
                                type="number"
                                placeholder="%"
                                min="0.00"
                                max="999.99"
                                v-model="form.aprc"
                                class="options-input options-price-input select-placeholder"
                                @focus="handleFocus($event)"
                                @focusout="handleFocusOut(form.aprc, $event)"
                            >
                          </div>
                        </div>
                        <p v-if="this.validationErrors && this.validationErrors.aprc" class="required-info text-center">
                          {{ this.validationErrors.aprc[0] }}
                        </p>
                      </div>

                    </div>
                    <!-- End Main Input fields -->

                    <!-- Start Horizontal Line with Icon -->
                    <div v-if="serviceTypeOccupation" class="horizontal-separator-box">
                      <span class="hr-line-short"></span>
                      <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                      <span class="hr-line-short"></span>
                    </div>
                    <!-- End Horizontal Line with Icon -->

                  </div>
                  <!-- End Service Details and Toggles Fields -->

                  <div v-if="serviceTypeOccupation" class="form-group col-12">
                    <label for="serviceDescription" class="pb-2">Advertise Description</label>
                    <div id="serviceDescription">
                      <TipTap ref="tiptap" v-model="form.service_description" :modelValue="form.service_description"/>
                      <!--                  <div class="content">-->
                      <!--                    <h3>Content</h3>-->
                      <!--                    <pre><code>{{ form.property_description }}</code></pre>-->
                      <!--                  </div>-->
                    </div>
                    <!--                <textarea rows="3" name="serviceDescription" v-model="form.property_description" id="serviceDescription" placeholder="Enter property description" class="form-control serviceDescription"/>-->
                  </div>

                  <div v-if="serviceTypeOccupation" class="form-group col-12">
                    <!-- Start Horizontal Line with Icon -->
                    <div class="horizontal-separator-box">
                      <span class="hr-line-short"></span>
                      <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                      <span class="hr-line-short"></span>
                    </div>
                    <!-- End Horizontal Line with Icon -->

                    <!-- Information -->
                    <small class="p-3 fw-bold">Contact Details</small>

                    <!-- Start Horizontal Line with Icon -->
                    <div class="horizontal-separator-box">
                      <span class="hr-line-short"></span>
                      <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                      <span class="hr-line-short"></span>
                    </div>
                    <!-- End Horizontal Line with Icon -->
                  </div>

                  <!-- Start Contact Details -->
                  <div v-if="serviceTypeOccupation" class="form-group text-start col-12">

                    <div class="form-group col-12 pb-2">
                      <div class="row m-0 p-0">
                        <div class="form-group col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 p-1 m-0">
                          <label class="font-weight-bold options-paragraph">Title</label> <span class="required">*</span>
                          <select
                              v-model="form.title"
                              class="form-select select-placeholder cursor-pointer"
                              @focus="handleFocus($event)"
                              @focusout="handleFocusOut(form.title, $event)"
                              tabindex="-1"
                              aria-label="title"
                              name="title"
                              id="title"
                          >
                            <option value="" disabled>Select title</option>
                            <option v-for="(item , index) in titleOptions" v-bind:key="index" :value="item.text" >
                              {{item.text}}
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 p-1 m-0">
                          <label for="name" class="font-weight-bold options-paragraph">Name</label> <span class="required">*</span>
                          <input type="text" name="name" maxlength="50" v-model="form.name" id="name" placeholder="Enter name" class="form-control">
                        </div>
                        <div class="form-group col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5 p-1 m-0">
                          <label for="surname" class="font-weight-bold options-paragraph">Surname</label> <span class="required">*</span>
                          <input type="text" name="surname" maxlength="50" v-model="form.surname" id="surname" placeholder="Enter surname" class="form-control">
                        </div>
                      </div>
                      <p v-if="this.validationErrors && this.validationErrors.title" class="required-info text-center">
                        {{ this.validationErrors.title[0] }}
                      </p>
                      <p v-if="this.validationErrors && this.validationErrors.name" class="required-info text-center">
                        {{ this.validationErrors.name[0] }}
                      </p>
                      <p v-if="this.validationErrors && this.validationErrors.surname" class="required-info text-center">
                        {{ this.validationErrors.surname[0] }}
                      </p>
                    </div>

                    <div class="form-group col-12 pb-2">
                      <div class="row m-0 p-0">
                        <div class="form-group col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8 p-1 m-0">
                          <label for="company_name" class="font-weight-bold options-paragraph">Company name</label>
                          <input type="text" name="company_name" maxlength="100" v-model="form.company_name" id="company_name" placeholder="Enter company name" class="form-control">
                          <!--                    <small>Note that mobile is the number that will be displayed on your advertising.</small>-->
                        </div>
                        <div class="form-group col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 p-1 m-0">
                          <label for="landing" class="font-weight-bold options-paragraph">Show company name</label><br/>
                          <label class="switch mt-2">
                            <input type="checkbox" v-model="form.show_company_name">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <!-- Address -->
                    <div class="col-12 pb-2">
                      <div class="row m-0 p-0">
                        <!-- Should be col-8, for time being col-12 as we do not do manual address enter -->
                        <div class="col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8 m-0 p-1">
                          <label class="font-weight-bold options-paragraph">Company Address</label>
                          <div id="geocoderAddEditServiceAddress"></div>
                        </div>
                        <div class="form-group col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 p-1 m-0">
                          <label for="landing" class="font-weight-bold options-paragraph">Show address</label><br/>
                          <label class="switch mt-2">
                            <input type="checkbox" v-model="form.show_address">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <p v-if="this.validationErrors && this.validationErrors.full_address" class="required-info text-center">
                        {{ this.validationErrors.full_address[0] }}
                      </p>
                    </div>

                    <!-- Start Horizontal Line with Icon -->
                    <div class="horizontal-separator-box">
                      <span class="hr-line-short"></span>
                      <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                      <span class="hr-line-short"></span>
                    </div>
                    <!-- End Horizontal Line with Icon -->

                    <!-- Information -->
                    <small class="p-3">Note that you need to display either mobile or email as a way to contact you. Those will be shown on your advertise.</small>

                    <!-- Start Horizontal Line with Icon -->
                    <div class="horizontal-separator-box">
                      <span class="hr-line-short"></span>
                      <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                      <span class="hr-line-short"></span>
                    </div>
                    <!-- End Horizontal Line with Icon -->

                    <div class="form-group col-12 pb-2">
                      <div class="row m-0 p-0">
                        <div class="form-group col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 p-1 m-0">
                          <label for="mobile-prefix" class="font-weight-bold options-paragraph">Prefix</label>
                          <input type="number" name="mobile-prefix" minlength="1" maxlength="7" v-model="form.mobile_prefix" id="mobile-prefix" placeholder="+" class="form-control">
                        </div>
                        <div class="form-group col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-1 m-0">
                          <label for="mobile" class="font-weight-bold options-paragraph">Mobile</label> <span class="required">*</span>
                          <input type="number" name="mobile" minlength="4" maxlength="15" v-model="form.mobile" id="mobile" placeholder="Enter mobile number" class="form-control">
                        </div>
                        <div class="form-group col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 p-1 m-0">
                          <label for="landing" class="font-weight-bold options-paragraph">Show mobile number</label><br/>
                          <label class="switch mt-2">
                            <input type="checkbox" v-model="form.show_mobile">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <p v-if="this.validationErrors && this.validationErrors.mobile_prefix" class="required-info text-center">
                        {{ this.validationErrors.mobile_prefix[0] }}
                      </p>
                      <p v-if="this.validationErrors && this.validationErrors.mobile" class="required-info text-center">
                        {{ this.validationErrors.mobile[0] }}
                      </p>
                      <p v-if="this.validationErrors && this.validationErrors.show_mobile" class="required-info text-center">
                        {{ this.validationErrors.show_mobile[0] }}
                      </p>
                    </div>

                    <div class="form-group col-12 pb-2">
                      <div class="row m-0 p-0">
                        <div class="form-group col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 p-1 m-0">
                          <label for="landing-prefix" class="font-weight-bold options-paragraph">Prefix</label>
                          <input type="number" name="landing-prefix" minlength="1" maxlength="7" v-model="form.landing_prefix" id="landing-prefix" placeholder="+" class="form-control">
                        </div>
                        <div class="form-group col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-1 m-0">
                          <label for="landing" class="font-weight-bold options-paragraph">Landing</label>
                          <input type="number" name="landing" minlength="4" maxlength="15" v-model="form.landing" id="landing" placeholder="Enter landing number" class="form-control">
                        </div>
                        <div class="form-group col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 p-1 m-0">
                          <label for="landing" class="font-weight-bold options-paragraph">Show landing number</label><br/>
                          <label class="switch mt-2">
                            <input type="checkbox" v-model="form.show_landing">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <p v-if="this.validationErrors && this.validationErrors.landing_prefix" class="required-info text-center">
                        {{ this.validationErrors.landing_prefix[0] }}
                      </p>
                      <p v-if="this.validationErrors && this.validationErrors.landing" class="required-info text-center">
                        {{ this.validationErrors.landing[0] }}
                      </p>
                      <p v-if="this.validationErrors && this.validationErrors.show_landing" class="required-info text-center">
                        {{ this.validationErrors.show_landing[0] }}
                      </p>
                    </div>

                    <div class="form-group col-12 pb-2">
                      <div class="row m-0 p-0">
                        <div class="form-group col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8 p-1 m-0">
                          <label for="email" class="font-weight-bold options-paragraph">Email</label> <span class="required">*</span>
                          <input type="text" name="email" maxlength="100" v-model="form.email" id="email" placeholder="Enter email" class="form-control">
                        </div>
                        <div class="form-group col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 p-1 m-0">
                          <label for="landing" class="font-weight-bold options-paragraph">Show email</label><br/>
                          <label class="switch mt-2">
                            <input type="checkbox" v-model="form.show_email">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <p v-if="this.validationErrors && this.validationErrors.email" class="required-info text-center">
                        {{ this.validationErrors.email[0] }}
                      </p>
                      <p v-if="this.validationErrors && this.validationErrors.show_email" class="required-info text-center">
                        {{ this.validationErrors.show_email[0] }}
                      </p>
                    </div>

                  </div>
                  <!-- End Contact Details -->

                  <!-- (#FREEPAYMENT) Start For now we comment this out, as all ads will be for free -->
<!--                  <div v-if="serviceTypeOccupation" class="form-group col-12 mt-3 mb-3">-->
<!--                    <Subscribe ref="purchase" @isPaidAd="changeAdPlan"/>-->
<!--                    <p v-if="this.validationErrors && this.validationErrors.is_free_ad" class="required-info text-center">-->
<!--                      {{ this.validationErrors.is_free_ad[0]}}-->
<!--                    </p>-->
<!--                    <p v-else-if="this.validationErrors && this.validationErrors.is_paid_ad" class="required-info text-center">-->
<!--                      {{ this.validationErrors.is_paid_ad[0] }}-->
<!--                    </p>-->
<!--                  </div>-->
                  <!-- (#FREEPAYMENT) End For now we comment this out, as all ads will be for free -->

<!--                  &lt;!&ndash; Useful error spitting for debugging &ndash;&gt;-->
<!--                  <div v-if="validationErrors" class="col-12 mb-2">-->
<!--                    <ValidationErrors :errors="validationErrors" v-if="validationErrors"/>-->
<!--                  </div>-->

                  <div v-if="serviceTypeOccupation" class="col-12 mb-2">
                    <button type="submit" :disabled="processing" @click.prevent="addService" class="btn-branding border-branding btn-block min-width-100">
                      {{ processing ? "Please wait" : "Submit" }}
                    </button>
                    <div v-if="processing" class="col-12 p-2 text-center">
                      <div class="spinner-border text-info" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <p v-if="this.validationCardMessage" class="required-info text-center">
                      {{ this.validationCardMessage }}
                    </p>
                    <p v-if="this.validationMessage && this.validationErrors && !this.validationErrors.free_ad_exists" class="required-info text-center">
                      {{ this.validationMessage }} Please scroll up to see if there's more error messages.
                    </p>
                    <p v-if="this.validationErrors && this.validationErrors && this.validationErrors.free_ad_exists" class="required-info text-center">
                      {{ this.validationErrors.free_ad_exists[0] }}
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- End Add Advertise Section - Form section -->
      <!-- Preview AdDetails Section -->
      <div class="col-12 col-lg-8 col-md-12 col-sm-12 col-xs-12">
        <div class="position-relative h-100">
          <div id="previewPropDetailsModal" class="preview-modal-tab">
            <!-- Loading -->
            <div class="fw-bold p-1 m-1" v-if="loading">Service Advert Preview</div>
            <hr v-if="loading"/>
            <div class="fw-bold p-5 m-2" v-if="loading">Please choose your occupation</div>
            <Loader :loading="loading" :small="false"></Loader>
            <PreviewAdService v-if="serviceTypeOccupation && !loading" :formData="form" :serviceTypeOccupation="serviceTypeOccupation"/>
          </div>
        </div>
      </div>
      <!-- End Preview AdDetails Section -->
    </div>

  </div>
</template>

<script>

import axios from 'axios';
import Loader from "./Loader.vue"
import TipTap from './TipTap.vue';
import PreviewAdService from './PreviewAdService';
import Subscribe from './Subscribe';
import ValidationErrors from './ValidationErrors';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import router from "../router";

export default {
  name:"AddEditService",
  components: {
    'Loader': Loader,
    'TipTap': TipTap,
    'PreviewAdService': PreviewAdService,
    'Subscribe': Subscribe,
    'ValidationErrors': ValidationErrors
  },
  data(){
    return {
      // Get serviceTypes from db and adjust those
      titleOptions: [
        { value: '1', text: 'Mr' },
        { value: '2', text: 'Mrs' },
        { value: '3', text: 'Miss' },
        { value: '4', text: 'Ms' }
      ],
      serviceTypeOptions: [],
      serviceTypeOccupationsOptions: [],
      serviceType: '',
      serviceTypeOccupation: '',
      form:{
        id: this.$route.params.id ? this.$route.params.id : '',
        user_id: '', // Should be pre-populated with user_id
        // Contact details must be provided on this stage
        /* Service contact details */
        contact_type_id: '', // Should be pre-populated with the id in controller (this should be added during creation of account)
        title: '',
        name: '',
        surname: '',
        company_name: '',
        show_company_name: false,
        landing_prefix: '',
        landing: '',
        show_landing: false,
        mobile_prefix: '',
        mobile: '',
        show_mobile: true,
        email: '',
        show_email: false,
        /* End of service contact details */
        /* Service address details */
        address_type_id: '', // Should be pre-populated with Service (id = 5 if I am correct - do it in controller)
        full_address: '',
        postcode: '',
        street: '',
        line_1: '',
        line_2: '',
        city: '',
        county: '',
        country: '',
        longitude: '',
        latitude: '',
        boundary_box: '',
        show_address: false,
        /* End of service address details */
        service_type_id: '', // based on serviceType
        service_type_occupation_id: '', // based on serviceTypeOccupation
        /* Working Hours */
        working_hours_from: null, // new Date(this.moment.utc().set("hour", 8).set("minute", 0).format('YYYY-MM-DD HH:mm:ss')),
        working_hours_to: null, // new Date(this.moment.utc().set("hour", 17).set("minute", 0).format('YYYY-MM-DD HH:mm:ss')),
        working_hours_from_flexible: false,
        working_hours_to_flexible: false,
        /* Service Area Cover Address */
        area_cover_full_address: '',
        area_cover_postcode: '',
        area_cover_street: '',
        area_cover_line_1: '',
        area_cover_line_2: '',
        area_cover_city: '',
        area_cover_county: '',
        area_cover_country: '',
        area_cover_longitude: '',
        area_cover_latitude: '',
        area_cover_boundary_box: '',
        /* End Servive Area Cover Address */
        service_title: '',
        service_description: '',
        price: '',
        service_payment_type_id: '',
        minimum: '',
        maximum: '',
        apr: '',
        aprc: '',
        rating_id: '',
        document_id: '',
        is_published: false,
        is_completed: false,
        is_deleted: false,
        is_free_ad: false, // false
        is_paid_ad: false, // false
        created_by: '',
        updated_by: '',
        deleted_by: '',
        // Extra bits necessary in form for previewAdDetails Component
        previewServiceImages: [],
        serviceImages: [],
        editServiceImages: []
      },
      validationErrors: '',
      validationMessage: '',
      validationCardMessage: '',
      processing: false,
      editing: false,
      editLoaded: false,
      editDataLoaded: false,
      editData: '',
      serviceId: '',
      loading: true
    }
  },
  methods: {
    handleFocus(event) {
      if (!event.target.classList.contains('select-clicked')) {
        event.target.classList.toggle('select-clicked');
      }
    },
    handleFocusOut(value, event) {
      if (value === null || value === '') {
        if (event.target.classList.contains('select-clicked')) {
          event.target.classList.toggle('select-clicked');
        }
      }
    },
    getServiceTypes: async function () {
      let headers = {
        'Retry-After': 3
      }
      return axios.post('api/service-types', [], {headers: headers}).then(({data}) => {
        this.serviceTypeOptions = data;
      })
    },
    getServiceTypeOccupations: async function () {
      let headers = {
        'Retry-After': 3
      }
      return axios.post('api/service-type-occupations', [], {headers: headers}).then(({data}) => {
        this.serviceTypeOccupationsOptions = data;
      })
    },
    assignSettings(settings) {
      // Setting Type Id 1 = show company name
      if (settings && settings.setting_type_id === 1) {
        this.form.show_company_name = settings.setting_value === '1';
      }
      // Setting Type Id 2 = show address
      if (settings && settings.setting_type_id === 2) {
        this.form.show_address = settings.setting_value === '1';
      }
      // Setting Type Id 3 = show mobile
      if (settings && settings.setting_type_id === 3) {
        this.form.show_mobile = settings.setting_value === '1';
      }
      // Setting Type Id 4 = show landing
      if (settings && settings.setting_type_id === 4) {
        this.form.show_landing = settings.setting_value === '1';
      }
      // Setting Type Id 5 = show email
      if (settings && settings.setting_type_id === 5) {
        this.form.show_email = settings.setting_value === '1';
      }
    },
    calculateImages(images = null, editImages = null) {
      // Check how many images added compared to set limit
      let count = 0;
      let countEditImages = 0;

      if (images) {
        count = images.length;
      }
      // check if edit images exists
      if (editImages) {
        countEditImages = editImages.length;
      }

      return count + countEditImages;
    },
    calculateAllowedImages(images, editImages = null, limit) {
      // Check how many images added compared to set limit
      if (images) {
        let count = images.length;
        let countEditImages = 0;

        // check if edit images exists
        if (editImages) {
          countEditImages = editImages.length;
        }
        if (limit - count - countEditImages >= 0) {
          return limit - count - countEditImages;
        } else {
          return 'Exceeded allowed number of images';
        }
        // Return maximum allowed
      } else {
        return limit;
      }
    },
    setDefaultImg(e) {
      e.target.src = require(`@/assets/no-image-found.png`);
    },
    uploadServiceImages(e) {
      this.form.previewServiceImages = [];
      this.form.serviceImages = [];

      // Restrict number of images in overall
      if (e.target.files.length > 7) {
        alert('Maximum number of allowed images is 7.');
        return;
      }

      let fileList = Array.prototype.slice.call(e.target.files);
      fileList.forEach(f => {

        if (!f.type.match("image.*")) {
          return;
        }

        this.form.serviceImages.push(f)

        let reader = new FileReader();
        let that = this;

        reader.onload = function (e) {
          that.form.previewServiceImages.push(e.target.result);
        }
        reader.readAsDataURL(f);
      });
      // console.log(this.form.previewServiceImages);
      // console.log(this.form.serviceImages);
    },
    changeAdPlan(plan) {
      if (plan === 1) {
        this.form.is_free_ad = false;
        this.form.is_paid_ad = true;
      }
      if (plan === 0) {
        this.form.is_free_ad = true;
        this.form.is_paid_ad = false;
      }
    },
    checkDate(date) {
      const endDate = Date.parse(date)
      let currentDate = new Date()
      currentDate = Date.parse(currentDate)

      return currentDate < endDate
    },
    async addService() {
      this.processing = true;
      let formData = new FormData();

      // Append all form data, also if statement changes true and false into 0 and 1
      for (let key in this.form) {
        // Prevent passing null values to back-end
        if(this.form[key] === null) {
          this.form[key] = '';
        }
        if (this.form[key] === false) {
          // Set is_free_ad or is_paid_ad empty rather than 0 so that 'required_without' rule in laravel validation can work
          this.form[key] = 0;
          if (key === 'is_free_ad' || key === 'is_paid_ad') {
            // this.form[key] = ''; (#FREEPAYMENT) For time being, as we want all ads to be is_paid_ad and for FREE
          }
        } else if (this.form[key] === true) {
          this.form[key] = 1;
        }

        // (#FREEPAYMENT) Temporarily, as we want all ads to be for free, this whole part will be unnecessary later on
        if (key === 'is_free_ad') {
          this.form[key] = '';
        }
        if (key === 'is_paid_ad') {
          this.form[key] = 1;
        }

        // Append Working Hours From To Dates
        if (key === 'working_hours_from') {
          if (this.form.working_hours_from !== null && this.form.working_hours_from !== '') {
            this.form[key] = this.moment(this.form.working_hours_from).format('DD-MM-YYYY HH:mm:ss');
          } else {
            this.form[key] = null;
          }
        }
        if (key === 'working_hours_to') {
          if (this.form.working_hours_to !== null && this.form.working_hours_to !== '') {
            this.form[key] = this.moment(this.form.working_hours_to).format('DD-MM-YYYY HH:mm:ss');
          } else {
            this.form[key] = null;
          }
        }

        formData.append(key, this.form[key]);
      }
      // Append service images
      if (this.form.serviceImages.length !== 0) {
        this.form.serviceImages.forEach((serviceImage, index) => {
          formData.append("serviceImages[]", serviceImage)
        })
      }

      let paymentResult = false;
      let paymentRequired = false;
      // If editing make sure that we want to run 'purchase' based on whether we changed subscription plan
      if (this.editing === true && this.$route.params.id && this.$route.params.id !== '') {
        // Check if is_paid_ad is set to true (1), and if its value is different than on initial editData
        // Then we know that it used to be free_ad and someone is upgrading to paid_ad (charge him)
        if (this.form.is_paid_ad === 1 && this.editData.data.is_paid_ad !== this.form.is_paid_ad) {
          // Validate before payment
          let validate = await axios.post('api/validate/services/' + this.$route.params.id + '/update', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(response => {
            this.validationErrors = null;
            this.validationMessage = null;
            return true;
          }).catch(({response}) => {
            if (response && response.data && response.data.errors) {
              this.validationErrors = response.data.errors;
              this.validationMessage = response.data.message;
              this.processing = false;
            }
            return false;
          }).finally(() => {
            // Processing still should be true, it will be set to false at the end of whole process
            // this.processing = false
          })

          // If validation went through proceed to payment and add advertise
          if (validate === true) {
            // #paymentFunction
            paymentRequired = false; // (#FREEPAYMENT) should be 'true' for time being we make it 'false' and comment out purchase()
            // paymentResult = await this.$refs.purchase.purchase();
          }
        }

        // I should add another check here, if current setup is 'is_paid_ad === 1' but its ad_ends_at is expired
        // we still should trigger purchase();
        if (this.form.is_paid_ad === 1 && this.editData.data.is_paid_ad === this.form.is_paid_ad && this.checkDate(this.editData.data.ad_ends_at) === false) {
          // Validate before payment
          let validate = await axios.post('api/validate/services/' + this.$route.params.id + '/update', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(response => {
            this.validationErrors = null;
            this.validationMessage = null;
            return true;
          }).catch(({response}) => {
            if (response && response.data && response.data.errors) {
              this.validationErrors = response.data.errors;
              this.validationMessage = response.data.message;
              this.processing = false;
            }
            return false;
          }).finally(() => {
            // Processing still should be true, it will be set to false at the end of whole process
            // this.processing = false
          })

          // If validation went through proceed to payment and add advertise
          if (validate === true) {
            // #paymentFunction
            paymentRequired = false; // (#FREEPAYMENT) should be 'true' for time being we make it 'false' and comment out purchase()
            // paymentResult = await this.$refs.purchase.purchase();
          }
        }

        // Check if is_free_ad is set to true (1), and if its value is different than on initial editData
        // Then we know that it used to be paid_ad and if ad_ends_at is not expired, we should ask for confirmation
        // Whether user is sure they want to change from 'paid_ad' to 'free_ad'
        if (this.form.is_free_ad === 1 && this.editData.data.is_free_ad !== this.form.is_free_ad && this.checkDate(this.editData.data.ad_ends_at) === true) {
          if (!confirm("Do you really want to change it to a free advertise? Your current advertise will expire on " + this.editData.data.ad_ends_at + " ?")) {
            return;
          }
        }

        if ((paymentRequired === true && paymentResult && paymentResult.status && paymentResult.status === true) || paymentRequired === false) {
          // Append Payment Results
          if (paymentResult) {
            formData.append('payment_id', paymentResult.id);
            formData.append('payment_status', paymentResult.status);
            formData.append('payment_amount', paymentResult.paymentAmount)
          }

          await axios.post('api/services/' + this.$route.params.id + '/update', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(response => {
            this.serviceId = response.data.id;
          }).catch(({response}) => {
            // alert(data.message)
            if (response && response.data && response.data.errors) {
              this.validationErrors = response.data.errors;
              this.validationMessage = response.data.message;
              this.editing = true;
            }
          }).finally(() => {
            this.processing = false
          })

          // Change editing to be false after it finished
          this.editing = false;

          // Redirect after editing
          if (!this.validationErrors && this.$route.params.id && this.$route.params.id !== '') {
            const serviceId = this.serviceId;

            // If payment is not required preset paymentResult
            if (paymentRequired === false) {
              paymentResult = {
                id: 'UPDATE',
                status: true,
                paymentAmount: 0
              };
            }

            await router.push({path: `/add-submit-confirmation/${serviceId}/service/${paymentResult.id}`})
          }
        } else {
          // Show error of payment
          this.validationCardMessage = paymentResult;
          this.processing = false
        }

      } else if (this.editing === false && (!this.$route.params.id || this.$route.params.id === '')) {
        // Validate before payment
        let validate = await axios.post('api/validate/services', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          this.validationErrors = null;
          this.validationMessage = null;
          return true;
        }).catch(({response}) => {
          if (response && response.data && response.data.errors) {
            this.validationErrors = response.data.errors;
            this.validationMessage = response.data.message;
            this.processing = false;
          }
          return false;
        }).finally(() => {
          // Processing still should be true, it will be set to false at the end of whole process
          // this.processing = false
        })

        // If validation went through proceed to payment and add advertise
        if (validate === true) {
          // #paymentFunction
          // paymentResult = await this.$refs.purchase.purchase(); // (#FREEPAYMENT) should be 'true' for time being we make it 'false' and comment out purchase()
          // on top of that we create an object below, to fill data
          paymentResult = {
            id: 'FREE',
            status: true,
            paymentAmount: 0
          }
          if (paymentResult && paymentResult.status && paymentResult.status === true) {
            // Append Payment Results
            if (paymentResult) {
              formData.append('payment_id', paymentResult.id);
              formData.append('payment_status', paymentResult.status);
              formData.append('payment_amount', paymentResult.paymentAmount)
            }

            await axios.post('api/services', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(response => {
              this.validationErrors = '';
              this.serviceId = response.data.id;
            }).catch(({response}) => {
              // alert(data.message)
              if (response && response.data && response.data.errors) {
                this.validationErrors = response.data.errors;
                this.validationMessage = response.data.message;
              }
            }).finally(() => {
              this.processing = false
            })

            // Redirect after adding
            if (!this.validationErrors) {
              const serviceId = this.serviceId;

              await router.push({path: `/add-submit-confirmation/${serviceId}/service/${paymentResult.id}`})
            }
          } else {
            // Show error of payment
            this.validationCardMessage = paymentResult;
            this.processing = false
          }
        }
      }
    },
    deleteImage(id, type, name) {

      if (confirm("Do you really want to delete " + name + " ?")) {
        axios.post('api/document/' + id + '/delete').then(({data}) => {
          // Type 5 = Service Image
          if (type === 5) {
            this.form.editServiceImages = this.form.editServiceImages.filter(serviceImage => serviceImage.id !== id);
          }
        })
      }
    },
    initGeocodeBox(type) {
      this.$nextTick(() => {

        // Mapbox Geocoding Address Autocomplete
        let geocoder = '';

        // We want to search only by locality if thats possible
        geocoder = new MapboxGeocoder({
          accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
          types: 'region,place,district,postcode,locality',
          limit: 7,
          countries: 'gb, ie, es', // Restrict to UK,Ireland and Spain only
        });

        let geoCheck = document.getElementById('geocoderAddEditServiceAreaCover');
        if (geoCheck) {
          if (geoCheck.children.length <= 0) {
            geocoder.addTo('#geocoderAddEditServiceAreaCover');
          }
        }

        // #addressPrepopulated If Editing Ad we want to prepopulate its address
        if (this.editData && this.editData.data.address && this.editData.data.address.full_address) {
          // Later I will use this solution, I am awaiting for second parameter to be added
          // https://github.com/mapbox/mapbox-gl-geocoder/pull/425
          // geocoderServiceAddress.setInput(this.editData.data.address.full_address);
          geocoder._inputEl.value = this.editData.data.address.full_address;
          // geocoder.query(this.editData.data.contact.address.full_address);
          // I need to emit some click or focus so it does not focus on geocoder...
          // It does not work no matter what I do... I need to work it out... damn
          this.$nextTick(() => {
            let suggestions = document.getElementsByClassName('suggestions');
            suggestions[0].style.display = 'none';
            suggestions[0].focus();
            suggestions[0].blur();
          })
        }

        // Get the geocoder results container.
        const results = document.getElementById('result');

        // Add geocoder result to container.
        geocoder.on('result', (e) => {
          // console.log(e);
          // results.innerText = JSON.stringify(e.result, null, 2);
          // For districts such as South Yorkshire, Hampshire etc.
          if (e.result.place_type.includes('district')) {
            for (let i = 0; i < e.result.context.length; i++) {
              if (e.result.context[i].id.includes('postcode')) {
                this.form.area_cover_postcode = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('locality')) {
                this.form.area_cover_line_2 = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('place')) {
                this.form.area_cover_city = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('country')) {
                this.form.area_cover_country = e.result.context[i].text;
              }
            }
            // district
            if (e.result.text) {
              this.form.area_cover_county = e.result.text;
            }
            // For places, which are cities, such as Manchester, Leeds etc.
          } else if (e.result.place_type.includes('place')) {
            for (let i = 0; i < e.result.context.length; i++) {
              if (e.result.context[i].id.includes('postcode')) {
                this.form.area_cover_postcode = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('locality')) {
                this.form.area_cover_line_2 = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('district')) {
                this.form.area_cover_county = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('country')) {
                this.form.area_cover_country = e.result.context[i].text;
              }
            }
            // Place - City
            if (e.result.text) {
              this.form.area_cover_city = e.result.text;
            }
          } else if (e.result.place_type.includes('postcode')) {
            for (let i = 0; i < e.result.context.length; i++) {
              if (e.result.context[i].id.includes('locality')) {
                this.form.area_cover_line_2 = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('district')) {
                this.form.area_cover_county = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('country')) {
                this.form.area_cover_country = e.result.context[i].text;
              }
            }
            // Postcode
            if (e.result.text) {
              this.form.area_cover_postcode = e.result.text;
            }
            // For locality such as Cudworth, Cosham etc.
          } else if (e.result.place_type.includes('locality')) {
            for (let i = 0; i < e.result.context.length; i++) {
              if (e.result.context[i].id.includes('district')) {
                this.form.area_cover_county = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('place')) {
                this.form.area_cover_city = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('country')) {
                this.form.area_cover_country = e.result.context[i].text;
              }
            }
            // Locality
            if (e.result.text) {
              this.form.area_cover_line_2 = e.result.text;
            }
            // For addresses, detailed ones such as 7 California Street etc. and all the rest
          } else {
            for (let i = 0; i < e.result.context.length; i++) {
              if (e.result.context[i].id.includes('postcode')) {
                this.form.area_cover_postcode = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('locality')) {
                this.form.area_cover_line_2 = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('place')) {
                this.form.area_cover_city = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('district')) {
                this.form.area_cover_county = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('country')) {
                this.form.area_cover_country = e.result.context[i].text;
              }
            }
            // First line of address
            if (e.result.text || e.result.address) {
              this.form.area_cover_line_1 = (e.result.address ? e.result.address + ' ' : '') + (e.result.text ? e.result.text : '');
            }
            // Street Only
            if (e.result.text) {
              this.form.area_cover_street = e.result.text ? e.result.text : '';
            }
          }

          // Full Address
          if (e.result.place_name) {
            this.form.area_cover_full_address = e.result.place_name;
          }
          // Coordinates
          if (e.result.center) {
            this.form.area_cover_longitude = e.result.center[0];
            this.form.area_cover_latitude = e.result.center[1];
          }
          // Boundary Box
          if (e.result.bbox) {
            this.form.area_cover_boundary_box = e.result.bbox;
          }
        });

        // Clear results container when search is cleared.
        geocoder.on('clear', () => {
          // results.innerText = '';
          this.form.area_cover_postcode = '';
          this.form.area_cover_line_2 = '';
          this.form.area_cover_city = '';
          this.form.area_cover_county = '';
          this.form.area_cover_country = '';
          this.form.area_cover_street = '';
          this.form.area_cover_line_1 = '';
          this.form.area_cover_longitude = '';
          this.form.area_cover_latitude = '';
          this.form.area_cover_boundary_box = '';
        });

      });
    },
    initGeocodeBoxServiceAddress(type) {
      this.$nextTick(() => {

        // Mapbox Geocoding Address Autocomplete
        let geocoderServiceAddress = '';

        // We want to search only by locality if thats possible
        geocoderServiceAddress = new MapboxGeocoder({
          accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
          types: 'region,place,district,postcode,locality,address',
          limit: 7,
          countries: 'gb, ie, es', // Restrict to UK,Ireland and Spain only
        });

        let geoCheck = document.getElementById('geocoderAddEditServiceAddress');
        if (geoCheck) {
          if (geoCheck.children.length <= 0) {
            geocoderServiceAddress.addTo('#geocoderAddEditServiceAddress');
          }
        }

        // #addressPrepopulated If Editing Ad we want to prepopulate its address
        if (this.editData && this.editData.data.contact && this.editData.data.contact.address && this.editData.data.contact.address.full_address) {
          // Later I will use this solution, I am awaiting for second parameter to be added
          // https://github.com/mapbox/mapbox-gl-geocoder/pull/425
          // geocoder.setInput(this.editData.data.contact.address.full_address);
          geocoderServiceAddress._inputEl.value = this.editData.data.contact.address.full_address;
          // geocoderServiceAddress.query(this.editData.data.address.full_address);
          // I need to emit some click or focus so it does not focus on geocoder...
          // It does not work no matter what I do... I need to work it out... damn
          this.$nextTick(() => {
            let suggestions = document.getElementsByClassName('suggestions');
            suggestions[0].style.display = 'none';
            suggestions[0].focus();
            suggestions[0].blur();
          })
        }

        // Get the geocoder results container.
        const results = document.getElementById('result');

        // Add geocoder result to container.
        geocoderServiceAddress.on('result', (e) => {
          // results.innerText = JSON.stringify(e.result, null, 2);
          // For districts such as South Yorkshire, Hampshire etc.
          if (e.result.place_type.includes('district')) {
            for (let i = 0; i < e.result.context.length; i++) {
              if (e.result.context[i].id.includes('postcode')) {
                this.form.postcode = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('locality')) {
                this.form.line_2 = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('place')) {
                this.form.city = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('country')) {
                this.form.country = e.result.context[i].text;
              }
            }
            // district
            if (e.result.text) {
              this.form.county = e.result.text;
            }
            // For places, which are cities, such as Manchester, Leeds etc.
          } else if (e.result.place_type.includes('place')) {
            for (let i = 0; i < e.result.context.length; i++) {
              if (e.result.context[i].id.includes('postcode')) {
                this.form.postcode = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('locality')) {
                this.form.line_2 = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('district')) {
                this.form.county = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('country')) {
                this.form.country = e.result.context[i].text;
              }
            }
            // Place - City
            if (e.result.text) {
              this.form.city = e.result.text;
            }
          } else if (e.result.place_type.includes('postcode')) {
            for (let i = 0; i < e.result.context.length; i++) {
              if (e.result.context[i].id.includes('locality')) {
                this.form.line_2 = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('district')) {
                this.form.county = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('country')) {
                this.form.country = e.result.context[i].text;
              }
            }
            // Postcode
            if (e.result.text) {
              this.form.postcode = e.result.text;
            }
            // For locality such as Cudworth, Cosham etc.
          } else if (e.result.place_type.includes('locality')) {
            for (let i = 0; i < e.result.context.length; i++) {
              if (e.result.context[i].id.includes('district')) {
                this.form.county = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('place')) {
                this.form.city = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('country')) {
                this.form.country = e.result.context[i].text;
              }
            }
            // Locality
            if (e.result.text) {
              this.form.line_2 = e.result.text;
            }
            // For addresses, detailed ones such as 7 California Street etc. and all the rest
          } else {
            for (let i = 0; i < e.result.context.length; i++) {
              if (e.result.context[i].id.includes('postcode')) {
                this.form.postcode = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('locality')) {
                this.form.line_2 = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('place')) {
                this.form.city = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('district')) {
                this.form.county = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('country')) {
                this.form.country = e.result.context[i].text;
              }
            }
            // First line of address
            if (e.result.text || e.result.address) {
              this.form.line_1 = (e.result.address ? e.result.address + ' ' : '') + (e.result.text ? e.result.text : '');
            }
            // Street Only
            if (e.result.text) {
              this.form.street = e.result.text ? e.result.text : '';
            }
          }

          // Full Address
          if (e.result.place_name) {
            this.form.full_address = e.result.place_name;
          }
          // Coordinates
          if (e.result.center) {
            this.form.longitude = e.result.center[0];
            this.form.latitude = e.result.center[1];
          }
          // Boundary Box
          if (e.result.bbox) {
            this.form.boundary_box = e.result.bbox;
          }
        });

        // Clear results container when search is cleared.
        geocoderServiceAddress.on('clear', () => {
          // results.innerText = '';
          this.form.postcode = '';
          this.form.line_2 = '';
          this.form.city = '';
          this.form.county = '';
          this.form.country = '';
          this.form.street = '';
          this.form.line_1 = '';
          this.form.longitude = '';
          this.form.latitude = '';
          this.form.boundary_box = '';
        });

      });
    }
  },
  async mounted() {

    await this.getServiceTypes();
    await this.getServiceTypeOccupations();

    /* START EDIT AD RELATED */

    // Checks for 'edit' ad rather than 'create' ad
    if(this.$route.params.id && this.$route.params.id !== '') {
      this.editing = true;
      await axios.get('api/services/' + this.$route.params.id + '/edit').then(({data}) => {
        // console.log(data);
        // We use that to #addressPrepopulated and #propertyCatTypePrepopulated
        this.editData = data;
        // Here I need to assign all the data to the form data... in a relevant way
        // How to do that in a nice way?... it would be painful to go one by one, with multiple if statements...

        // Assign addresses manually as mapbox query() and setInput() does not help at all... due to suggestions window popping up
        // Assign Property Address
        if (data.data && data.data.address) {
          Object.keys(data.data.address).forEach((key) => {
            // console.log('Key : ' + key + ', Value : ' + data.data.address[key])
            if (key === 'full_address') {
              this.form.area_cover_full_address = data.data.address[key];
            }
            if (key === 'postcode') {
              this.form.area_cover_postcode = data.data.address[key];
            }
            if (key === 'street') {
              this.form.street = data.data.address[key];
            }
            if (key === 'line_2') {
              this.form.area_cover_line_2 = data.data.address[key];
            }
            if (key === 'city') {
              this.form.area_cover_city = data.data.address[key];
            }
            if (key === 'county') {
              this.form.area_cover_county = data.data.address[key];
            }
            if (key === 'country') {
              this.form.area_cover_country = data.data.address[key];
            }
            if (key === 'street') {
              this.form.area_cover_street = data.data.address[key];
            }
            if (key === 'line_1') {
              this.form.area_cover_line_1 = data.data.address[key];
            }
            if (key === 'longitude') {
              this.form.area_cover_longitude = data.data.address[key];
            }
            if (key === 'latitude') {
              this.form.area_cover_latitude = data.data.address[key];
            }
            if (key === 'boundary_box') {
              this.form.area_cover_boundary_box = data.data.address[key];
            }
          });
        }

        // Assign Property Contact Address
        if (data.data && data.data.contact && data.data.contact.address) {
          Object.keys(data.data.contact.address).forEach((key) => {
            // console.log('Key : ' + key + ', Value : ' + data.data.contact.address[key])
            if (key === 'full_address') {
              this.form.full_address = data.data.contact.address[key];
            }
            if (key === 'postcode') {
              this.form.postcode = data.data.contact.address[key];
            }
            if (key === 'street') {
              this.form.street = data.data.address[key];
            }
            if (key === 'line_2') {
              this.form.line_2 = data.data.contact.address[key];
            }
            if (key === 'city') {
              this.form.city = data.data.contact.address[key];
            }
            if (key === 'county') {
              this.form.county = data.data.contact.address[key];
            }
            if (key === 'country') {
              this.form.country = data.data.contact.address[key];
            }
            if (key === 'street') {
              this.form.street = data.data.contact.address[key];
            }
            if (key === 'line_1') {
              this.form.line_1 = data.data.contact.address[key];
            }
            if (key === 'longitude') {
              this.form.longitude = data.data.contact.address[key];
            }
            if (key === 'latitude') {
              this.form.latitude = data.data.contact.address[key];
            }
            if (key === 'boundary_box') {
              this.form.boundary_box = data.data.contact.address[key];
            }
          });
        }

        // Assign Service Data
        Object.keys(data.data).forEach((key) => {
          // console.log('Key : ' + key + ', Value : ' + data.data[key])
          Object.keys(this.form).forEach((formKey) => {
            if(key === formKey) {
              this.form[formKey] = data.data[key];

              // Assign is_free_ad and is_paid_ad to be true or false rather than 1 and 0, as otherwise it wont assign proper value from
              // the checkbox in the front-end
              // Same situation with is_free_ad and is_paid_ad as above
              if(key === 'is_free_ad' && key === formKey) {
                this.form.is_free_ad = data.data[key] === 1;
                // #lifeCycleProblem - I had to trigger child component function later below the code, for some reason
                // it is undefined at this point.. not sure why
                // this.$refs.purchase.setAdPlanOnEdit(data.data[key]);
              }
              if(key === 'is_paid_ad' && key === formKey) {
                this.form.is_paid_ad = data.data[key] === 1;
                // #lifeCycleProblem - I had to trigger child component function later below the code, for some reason
                // it is undefined at this point.. not sure why
                // this.$refs.purchase.setAdPlanOnEdit(data.data[key]);
              }

              // Assign working_hours_from and to dates (times) - note that we need singular_date being assigned before that
              // And that we need to consider whether values is 'Flexible' due to is_flexible toggles
              if(key === 'working_hours_from' && key === formKey) {
                if(data.data.working_hours_from === null || data.data.working_hours_from === 'Flexible') {
                  this.form.working_hours_from = null;
                  // this.firstLoad = false;
                } else {
                  this.form.working_hours_from = new Date(this.moment.utc(data.data.working_hours_from, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));
                }
              }
              if(key === 'working_hours_to' && key === formKey) {
                if(data.data.working_hours_to === null || data.data.working_hours_to === 'Flexible') {
                  this.form.working_hours_to = null;
                  // this.firstLoad = false;
                } else {
                  this.form.working_hours_to = new Date(this.moment.utc(data.data.working_hours_to, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));
                }
              }
            }
          })
        })

        // Assign Occupation Data
        if (data.data && data.data.occupation) {
          this.serviceTypeOccupation = data.data.occupation;
        }

        // Assign Contact Details
        Object.keys(data.data.contact).forEach((contactDetailsKey) => {
          // console.log('Key : ' + contactDetailsKey + ', Value : ' + data.data.contact[contactDetailsKey])
          Object.keys(this.form).forEach((formDetailsKey) => {
            if(contactDetailsKey === formDetailsKey) {
              // We want to apply only to specific keys
              if (contactDetailsKey === 'company_name' ||
                  contactDetailsKey === 'contact_type_id' ||
                  contactDetailsKey === 'email' ||
                  contactDetailsKey === 'landing' ||
                  contactDetailsKey === 'landing_prefix' ||
                  contactDetailsKey === 'mobile' ||
                  contactDetailsKey === 'mobile_prefix' ||
                  contactDetailsKey === 'name' ||
                  contactDetailsKey === 'surname' ||
                  contactDetailsKey === 'title'
              ) {
                this.form[formDetailsKey] = data.data.contact[contactDetailsKey];
              }
            }
          })
        })

        // Assign Settings
        let countSettings = data.data.settings ? data.data.settings.length : null;
        if (countSettings) {

          for (let i=0; i<countSettings; i++) {
            Object.keys(data.data.settings[i]).forEach((settingsKey) => {
              // console.log('Key : ' + settingsKey + ', Value : ' + data.data.settings[i][settingsKey])
              this.assignSettings(data.data.settings[i]);
            })
          }

        }

        // Assign Service Images
        if(this.editData.data.documents_service_images && this.editData.data.documents_service_images.length > 0) {
          Object.keys(this.editData.data.documents_service_images).forEach((propKey) => {
            // console.log('Key : ' + propKey + ', Value : ' + this.editData.data.documents_service_images[propKey]);
            this.form.editServiceImages.push(this.editData.data.documents_service_images[propKey]);

            // let requireImage = "require(`@/assets/" + this.editData.data.documents_service_images[propKey].file_path + "`)";
            // console.log(requireImage);
            // I am encountering new problem here, I need to either store also base64 string of images into db
            // or blob... as fileReader() cannot handle just the URL in form of file_path.. I need somehow to get
            // images being previewed on PreviewAdDetails.vue... and also list the rest of the files as a list, so one can
            // remove them on click (remove from DB)
            // My solution might need revision, as it seems a bit pointless to do it that way as described above,
            // I will be using S3 buckets so I should make sure to make it working for S3 buckets, on AddEditAd, AdDetails,
            // List and PreviewAdDetails components

            // this.form.propImages.push(this.editData.data.documents_service_images[docKey].file_path);
            // that.form.previewFloorplanImages.push(e.target.result);
            // Object.keys(this.form).forEach((formDetailsKey) => {
            //   if(propDetailsKey === formDetailsKey) {
            //     this.form[formDetailsKey] = data.data.property_details[propDetailsKey];
            //   }
            // })
          })
        }

      })

      // #lifeCycleProblem - Not sure why but it seems that I cannot run $refs of child components in main function above,
      // I had to do it in here... weird...
      this.$nextTick(() => {
        // Set payment plan in subscribe
        // this.$refs.purchase.setAdPlanOnEdit(this.form.is_paid_ad); // (#FREEPAYMENT) comment out for now
      })

      // #SkipWatchEdit Used to skip first trigger of serviceType watch on Edit Ad
      this.$nextTick(() => {
        this.editDataLoaded = true
      })

    }
    /* END EDIT AD RELATED */

    // #SkipWatchEdit Used to skip first trigger of serviceType watch on Edit Ad
    this.$nextTick(() => {
      this.editDataLoaded = true
    })

    // this.$nextTick(() => {
    //   // this.editLoaded = true
    //   // Ok the problem here is that once we assign the value as expected, suddenly seems like the component is reloaded one
    //   // more time, hence it resets the value
    //   this.$refs.purchase.setAdPlanOnEdit(this.form.is_paid_ad);
    //   // this.form.property_description = this.form.property_description + '';
    // })
  },
  watch: {
    "serviceTypeOccupation": function (type) {
      // Set service type occupation in the form
      if (type) {
        this.form.service_type_occupation_id = type.id;
      }

      // #SkipWatchEdit This if statement is necessary for Edit Ad as in the below switch we resset some values
      if (this.editDataLoaded) {

      }
      this.loading = false;

      if (type === null) {
        this.loading = true;
      }
      // Add geocoder search box to address fields
      this.initGeocodeBox(type);
      this.initGeocodeBoxServiceAddress(type);
    },
  }
}
</script>

<style lang="scss">

/* Map Box Geocoder */
#geocoder {
  z-index: 1;
}
.mapboxgl-ctrl-geocoder {
  border-radius: .25rem;
  border: 1px solid #ced4da;
  width: 100%;
  box-shadow: none;
  min-width: 100%;
}
/* End Map Box Geocoder */

#mainMenu {
  position: relative;
  width: 95%;
  button.mainMenuButton {
    color: white;
    background-color: black;
    width: 120px;
    font-size: 12px;
    text-align: left;
    border-radius: 10px;
    transition: 0.6s;
    margin-left: 5px;
  }
  button:hover.mainMenuButton {
    color: black;
    background-color: white;
    transition: 0.6s;
  }
}
.firstLevelList {
  width: 90%;
  position: absolute;
  left: 100%;
  border: none;
  margin: 0;
  padding: 0;
  li {
    padding: 0;
    margin: 0;
  }
  button.firstLevelButton {
    color: black;
    background-color: white;
    width: 150px;
    font-size: 12px;
    text-align: left;
    border-radius: 10px;
    transition: 0.6s;
  }
  button:hover.firstLevelButton {
    color: white;
    background-color: black;
    transition: 0.6s;
  }
}
.secondLevelList {
  position: absolute;
  left: 100%;
  width: 90%;
  border: none;
  margin: 0;
  padding: 0;
  li {
    padding: 0;
    margin: 0;
  }
  button.secondLevelButton {
    color: black;
    background-color: white;
    width: 160px;
    font-size: 12px;
    text-align: left;
    border-radius: 10px;
    transition: 0.6s;
  }
  button:hover.secondLevelButton {
    color: white;
    background-color: black;
    transition: 0.6s;
  }
}

.mainMenu:hover .dropdown-menu {
  width: 100%;
  display: block;
  margin-top: 0; // remove the gap so it doesn't close
  .firstLevel .dropdown-menu {
    display: none;
  }
  .firstLevel:hover .dropdown-menu {
    width: 100%;
    display: block;
    margin-top: 0; // remove the gap so it doesn't close
  }
}

/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'Arial', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
}

/* ===== Toggles ===== */
/* The switch - the box around the slider */
.switch {
  position: relative;
  text-align: center;
  display: inline-block;
  line-height: 20px;
  -moz-block-height: 20px;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 1px;
  bottom: 1.5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.options-paragraph {
  font-size: 14px;
}

.options-select {
  width: 100%;
}

.options-input {
  border-radius: .25rem;
  border: 1px solid #ced4da;
  width: 100%;
  padding: .375rem .25rem .375rem .75rem;
}
.options-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

/* start tooltip - popups info on icons */
.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  white-space: pre-wrap;      /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  //white-space: -pre-wrap;     /* Opera <7 */
  white-space: -o-pre-wrap;   /* Opera 7 */
  word-wrap: break-word;      /* IE */

  /* Position the tooltip */
  position: absolute;
  z-index: 1000;
}

[data-tooltip]:hover .tooltiptext {
  visibility: visible;
}
/* end tooltip - popups info on icons */

/* Start Text truncate - white space - for long texts and icons */
.list-relative-contact {
  width: 100%;
  position: relative;
  min-height: 30px;
}

.list-relative {
  width: 100%;
  position: relative;
}

.text-truncate-item {
  width: 100%;
  box-sizing:border-box;
  transition: 0.7s;
  border-radius: 5px;
}

.text-truncate-item:hover {
  position: absolute;
  transition: 0.7s;
  box-sizing:border-box;
  overflow: visible !important;
  white-space: normal !important;
  background-color: white;
  border: 1px solid black;
  padding: 0 0 0 1px;
  border-radius: 5px;
  z-index: 1000;
}

.options-text-truncate-item {
  width: 100%;
  box-sizing:border-box;
  transition: 0.7s;
  border-radius: 5px;
}
.options-text-truncate-item:hover {
  position: absolute;
  transition: 0.7s;
  box-sizing:border-box;
  overflow: visible !important;
  background-color: white;
  z-index: 1000;
}
/* End Text truncate - white space - for long texts and icons */

/* ===== End toggles ===== */

/* Start Upload Files */
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 100px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1em;
  text-align: center;
  padding: 10px 0;
}

.uploading-property-images {
    width: 100%;
    max-width: 100%;
    height: 120px;
    max-height: 120px;
}

.uploading-floorplan-images {
  width: 100%;
  max-width: 100%;
  height: 120px;
  max-height: 120px;
}

.delete-btn {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: block;
  margin: auto;
  font-size: 9px;
  padding: 0;
  transition: 0.6s;
  border: 0;
  color: white;
  background-color: #dc3545;
}

.delete-btn:hover {
  color: black;
  border: 1px solid #dc3545 !important;
  background-color: white;
  transition: 0.6s;
}
/* End Upload Files */

/* Preview Ad Details */
#previewPropDetailsModal {
  width: 100%;
  border: 1px solid rgba(0,0,0,.125);
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  height: 76vh;
  position: sticky;
  z-index: 1;
  top: 18%;
  left: 36vw;
  //background-color: rgba(33, 150, 243, 50%);
  background-color: rgba(255, 255, 255, 70%);
  overflow-x: hidden;
  transition: 0.5s;
  border-radius: 5px;
  background-clip: border-box;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.preview-modal-tab::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.preview-modal-tab {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* End Preview Ad Details */

///* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
//@media screen and (max-width: 499px) {
//  /* Preview Ad Details */
//  #previewPropDetailsModal {
//    height: 100%;
//    position: relative;
//    top: 0;
//    left: 0;
//  }
//}
//@media screen and (min-width: 500px) {
//  #previewPropDetailsModal {
//    height: 100%;
//    position: relative;
//    top: 0;
//    left: 0;
//  }
//}
//@media screen and (min-width: 768px) {
//  #previewPropDetailsModal {
//    height: 100%;
//    position: relative;
//    top: 0;
//    left: 0;
//  }
//}
//@media screen and (min-width: 992px) {
//  #previewPropDetailsModal {
//    height: 100%;
//    position: sticky;
//    top: 22%;
//    left: 36vw;;
//  }
//}
//@media screen and (min-width: 1200px) {
//  #previewPropDetailsModal {
//    height: 73vh;
//    position: sticky;
//    top: 22%;
//    left: 36vw;;
//  }
//}
//@media screen and (min-width: 1920px) {
//  #previewPropDetailsModal {
//    height: 73vh;
//    position: sticky;
//    top: 22%;
//    left: 36vw;;
//  }
//}

</style>